'use client';
import { FC, useEffect } from 'react';

import { usePathname } from 'next/navigation';

export const WebLogic: FC = () => {
  const pathname = usePathname();

  useEffect(() => {
    // Check if the div with classname 'driftrock' exists in the DOM
    const driftrockDiv = document.querySelector('.driftrock');
    if (driftrockDiv) {
      // If it exists, remove the div
      driftrockDiv.remove();
    }
    if ((window as any).Driftrock) {
      (window as any).Driftrock.init({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        api_key: `${process.env.NEXT_PUBLIC_DRIFTROCK_KEY}`,
      });
    }
  }, [pathname]);

  return null;
};

WebLogic.displayName = 'WebLogic';
